import {
  Wallpaper,
  BarcodeContainer,
  Header,
  SignIn,
} from "../../pages/Barcode/BarcodeStyles";
import { PictureContainer } from "../QRCode/QrCodeStyles";
import CycuridLogo from "../../assets/CycurID_Title_Logo.png";
import { Button } from "../Timeout/TimeOutStyle";
import ErrorImage from "../../assets/ErrorImage.svg";
import { MainText } from "./ErrorPageStyle";

function ErrorPage({ cancel }) {
  return (
    <>
      <Wallpaper>
        <BarcodeContainer>
          <Header>
            <img
              src={CycuridLogo}
              alt="logo"
              height="28.4px"
              width="100.33"
              style={{ marginLeft: 37 }}
            />
          </Header>
          <SignIn />
          <PictureContainer style={{ flexDirection: "column" }}>
            <MainText>
              We are unable to process your request
              <img
                src={ErrorImage}
                alt="error"
                style={{ marginTop: 35, marginRight: 10 }}
              />
            </MainText>
          </PictureContainer>
          <Button onClick={cancel} style={{ marginTop: 110 }}>
            Go back to sign in page
          </Button>
        </BarcodeContainer>
      </Wallpaper>
    </>
  );
}

export default ErrorPage;

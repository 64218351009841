import { DescriptionContainer } from "./BottomDescriptionStyles";

function BottomDescription({ description }) {
  return (
    <DescriptionContainer>
      <p style={{ textAlign: "center", marginTop: "30px" }}>
        <span style={{ fontWeight: 700 }}> CycurID™ </span>
        is consumer centric privacy and identity app. It is a proprietary,
        secure, closed loop and encrypted identity verification and privacy app
        which makes it possible for consumers to set up a social profile, manage
        crypto funds or transact online without sharing any, or minimal, amounts
        of personal information.
      </p>
    </DescriptionContainer>
  );
}

export default BottomDescription;

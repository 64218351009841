import styled from "styled-components";
import { viewport } from "../Viewports";

export const Container = styled.div`
    height: 130px;
    width: 100%;
    // background-color: yellow;
    align-items: center;
    justify-content: center;

    @media (max-width: ${viewport.tablet}) {
        height: 80px;
        margin-bottom: 20px;
    }
`
;

export const SubSection = styled.div`
    height: 50px;
    width: 100%;
    // background-color: orange;
    align-items: center;
    display: flex;
    justify-content: center;

    @media (max-width: ${viewport.tablet}) {
        height: 40px;
    }
`
;

export const TextSection = styled.div`
    // background-color: pink;
    width: 50%;
    height: 32px;
    display: inline-block;
    align-items: center;
    padding-left: 4px;
    flex-direction: column;
    font-family: poppins;
    font-size: 14px;

    @media (max-width: ${viewport.tablet}) {
        width: 65%;
        font-size: 13px;
    }
`

export const TitleText = styled.p`
    font-sze: 14px;
    margin: 0px;
    font-family: poppins;

    @media (max-width: ${viewport.tablet}) {
        font-size: 13px;
    }
;
`


;

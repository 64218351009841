import styled from 'styled-components'
import { viewport } from '../Viewports';

export const Animation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 100%;
`
;


export const SuccessTextBox = styled.div`
    font-family: poppins;
    font-size: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 175px;
    width: 100%;
    justify-content: flex-end;

    // background-color: pink;

    @media (max-width: ${viewport.tablet}) {
        font-size: 13px;
        height: 125px;
    }
`
;
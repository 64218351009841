import { useEffect, useState, useRef } from "react";
import QRCodeStyling from "qr-code-styling";
import CycuridLogoTiny from "../../assets/cycuridLogo.png";
import { PictureContainer } from "./QrCodeStyles";

function QrCode({ barcodeData }) {
  const ref = useRef(null);
  const [one, setOne] = useState(false);

  // creating the barcode
  const qrCode = new QRCodeStyling({
    width: 220,
    height: 220,
    margin: 0,
    type: "svg",
    data: `${barcodeData}`, //put all the data from server thats needed to put inside the phone
    image: `${CycuridLogoTiny}`,
    dotsOptions: {
      color: "black",
      type: "classyRounded",
    },
    backgroundOptions: {
      color: "white",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 6,
      imageSize: 0.4,
    },
  });

  // if there is already a barcode, dont create another one on a refresh.
  useEffect(() => {
    if (!one) {
      qrCode.append(ref.current);
      setOne(true);
    }
  }, [setOne]);

  return (
    <PictureContainer>
      {/* two divs stacked ontop of each other, to create the corner borders. */}
      {/* ===================================================================== */}
      {/* barcode circle div */}
      <div
        ref={ref}
        style={{
          position: "relative",
          zIndex: 1,
          borderRadius: "50%",
          padding: 17,
          background: "white",
        }}
      />
      {/* corner border div */}
      <div
        style={{
          position: "absolute",
          height: 223,
          width: 223,
          border: "2px solid #30a43b",
          borderRadius: 5,
          zIndex: 0,
        }}
      />
      {/* ===================================================================== */}
    </PictureContainer>
  );
}

export default QrCode;

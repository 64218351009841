import dayjs from 'dayjs'

export function getRemainingTimeUntulMsTimestampe(timestamp) {

    const timestampDay = dayjs(timestamp)
    const nowDayjs = dayjs()

    if (timestampDay.isBefore(nowDayjs)) {
        return {
            seconds: '00',
            minutes: '0'
        }
    }

    return {
        seconds: getRemainingSeconds(nowDayjs, timestampDay),
        minutes: getRemainingMinutes(nowDayjs, timestampDay)
    }
}

function getRemainingSeconds(nowDayjs, timestampDay) {
    const seconds = timestampDay.diff(nowDayjs, 'seconds') % 60
    return padWithZeros(seconds, 2)
}

function getRemainingMinutes(nowDayjs, timestampDay) {
    const minutes = timestampDay.diff(nowDayjs, 'minutes') % 60
    return padWithZeros(minutes, 2)
}

function padWithZeros(number, minimumLength) {
    const numberString = number.toString()
    if (numberString.length >= minimumLength) return numberString
    return '0' + numberString // make sure if its a single digit number, it will have a '0' in front.
}
import { useEffect, useState } from "react";
import {
  Wallpaper,
  BarcodeContainer,
  Header,
  Cancel,
  SignIn,
  ToContinue,
  Timer,
} from "./BarcodeStyles";
import CycuridLogo from "../../assets/CycurID_Title_Logo.png";
import QrCode from "../../components/QRCode/QrCode";
import Instructions from "../../components/Instructions/Instructions";
import BottomDescription from "../../components/BottomDescription/BottomDescription";
import Processing from "../../components/Processing/Processing";
import Complete from "../../components/Complete/Complete";
import { PictureContainer } from "../../components/QRCode/QrCodeStyles";
import axios from "axios";
import TimerIcon from "../../assets/TimerIcon.svg";
import { getRemainingTimeUntulMsTimestampe } from "../../components/Functions/RemainingTime";
import Verifying from "../../components/Verifying/Verifying.js";
import TimeOut from "../../components/Timeout/TimeOut";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import axiosRequest from "../../components/Functions/axiosRequest";

function Barcode() {
  const [status, setStatus] = useState();
  const [listening, setListening] = useState(false);
  const [clientID, setClientID] = useState();
  const [sessionID, setSessionID] = useState();
  const [token, setToken] = useState();
  const [barcodeData, setBarcodeData] = useState();
  const [timerToken, setTimerToken] = useState(false);
  const [timer, setTimer] = useState({ minutes: "30", seconds: "00" });
  const [merchantSite, setMerchantSite] = useState(null);
  const [merchantName, setMerchantName] = useState(null);
  const [merchantLogo, setMerchantLogo] = useState(null);
  const [scope, setScope] = useState();
  const [code, setCode] = useState();
  const [redirectURL, setRedirectURL] = useState();

  //============================== LISTEN FOR DATA ==================================================>

  async function listenForData() {
    try {
      async function getStatus(ci, ss, tk) {
        return axiosRequest(
          "post",
          `${process.env.REACT_APP_STAGING}/v2/cycurid-connect/widget/status`,
          {
            clientId: ci,
            session: ss,
          },
          {
            Authorization: `Bearer ${tk}`,
          }
        );
      }
      const response = await getStatus(clientID, sessionID, token);

      if (response.status === 200) {
        setStatus(response.data.status);
        if (response.data.code) {
          setListening(false);
          setCode(response.data.code);
        }
      } else if (response.status === 401) {
        setStatus(3);
      }
    } catch (err) {
      if (err.response.status === 422) {
        setStatus(3);
      } else if (err.response.status === 403) {
        setStatus(3);
        setCode(3);
      } else {
        setStatus(5);
      }
    }
  }

  //============================== TIMER ================================================================>
  function updateRemainingTime(currentDate) {
    setTimer(getRemainingTimeUntulMsTimestampe(currentDate));
  }

  useEffect(() => {
    if (timerToken) {
      const currentDate = Date.now() + 1000 * 60 * 30;
      const interval = setInterval(() => {
        updateRemainingTime(currentDate);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timerToken]);

  useEffect(() => {
    if (timer.minutes.toString() === "0" && timer.seconds.toString() === "00") {
      setTimerToken(false);
      setStatus(5);
    }
  }, [timer]);

  //============================== WINDOW POP-UP ====================================================>

  useEffect(() => {
    const location = window.location.search;
    let params = new URLSearchParams(location);
    const client = params.get("client_id");
    const redirectUri = params.get("redirect_uri");
    const scope = params.get("scope");
    const action = params.get("action");
    const codeChallenge = params.get("code_challenge");
    let requestParamsBody = {};
    setMerchantSite(redirectUri);
    setClientID(client);
    setScope(scope);
    setRedirectURL(redirectUri);

    // if (entityName) {
    //   requestParamsBody = {
    //     clientId: client,
    //     callBackUrl: redirectURL,
    //     scope: scope,
    //     action: action,
    //     responseType: "code",
    //     entity_name: entityName,
    //   };
    // } else {
    //   requestParamsBody = {
    //     clientId: client,
    //     responseType: "code",
    //     callBackUrl: redirectURL,
    //     scope: scope,
    //     action: action,
    //   };
    // }
    axios
      .post(
        `${process.env.REACT_APP_STAGING}/v2/cycurid-connect/widget/initialize`,
        {
          clientId: client,
          codeChallenge: codeChallenge,
          redirectUri: redirectUri,
          scope: scope,
          action: action,
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 404 || response.data.status === 422) {
          setStatus(6);
          setTimerToken(false);
        } else {
          setBarcodeData(
            JSON.stringify({
              clientId: client,
              session: response.data.session,
              service: response.data.action,
              scope: scope,
              entity_name: response.data.displayName,
            })
          );

          setMerchantName(response.data.displayName);
          setToken(response.data.token);
          setTimerToken(true);
          setStatus(response.data.status);
          setSessionID(response.data.session);
          setListening(true);
        }
      })
      .catch((e) => {
        console.log(e.response);
        setStatus(6);
        setTimerToken(false);
      });

    // setBarcodeData(
    //   JSON.stringify({
    //     client_id: client,
    //     session:
    //       "owtTnJzzAYf/tSpLLSrcHERuyyCa82EJT4OloV3z0J5OrDC//OjuWcQxK8cmzxwQJFhaQEYHbEW3nnN4E/mi4Q==",
    //     service: action,
    //     merchantSite: redirectURL,
    //     scope: scope,
    //   })
    // );
    setStatus(0);
  }, []);

  //============================ CALLBACK TO ALWAYS LISTEN ===============================>

  useEffect(async () => {
    if (listening) {
      listenForData();
    }
  }, [listenForData]);

  const cancel = () => {
    window.close();
  };

  const complete = () => {
    setStatus(2);
  };

  return (
    <>
      {status === 0 ? (
        <Wallpaper>
          <BarcodeContainer>
            <Header>
              <img
                src={CycuridLogo}
                alt="logo"
                height="28.4px"
                width="100.33"
                style={{ marginLeft: 37 }}
              />
              <Cancel onClick={(e) => cancel()} style={{ paddingRight: 40 }}>
                Cancel
              </Cancel>
            </Header>
            <SignIn> Sign In </SignIn>

            <ToContinue>
              <p> to continue to</p>
              <p style={{ color: "#30a43b" }}>&ensp;{merchantName} </p>
            </ToContinue>
            <PictureContainer>
              {barcodeData ? <QrCode barcodeData={barcodeData} /> : null}
            </PictureContainer>
            <Instructions />
            <Timer>
              <div style={{ display: "flex", alignItems: "center", width: 61 }}>
                <img src={TimerIcon} alt="clock" height="14px" />
                <p style={{ color: "#30a43b", fontWeight: "600" }}>
                  &ensp;{timer.minutes}:{timer.seconds}
                </p>
              </div>
              <div>
                <span style={{ color: "#7D7D7D", fontWeight: "500" }}>
                  {" "}
                  Time remaining to complete your task{" "}
                </span>
              </div>
            </Timer>
            <BottomDescription />
          </BarcodeContainer>
        </Wallpaper>
      ) : // verifying/authorization after barcode scan
      status === 1 ? (
        <Verifying
          minutesLeft={timer.minutes}
          secondsLeft={timer.seconds}
          merchantName={merchantName}
          merchantSite={merchantSite}
          status={status}
        />
      ) : // success && access denied!!!
      status === 2 || status === 3 ? (
        <Complete
          merchantName={merchantName}
          status={status}
          merchantSite={merchantSite}
          code={code && code}
          redirectURL={redirectURL}
        />
      ) : // timeout!!!
      status === 5 ? (
        <TimeOut cancel={cancel} />
      ) : // error page!!
      status === 6 ? (
        <ErrorPage cancel={cancel} />
      ) : (
        <>
          <p onClick={complete}>Test Complete</p>
          <Processing cancel={cancel} />
        </>
      )}
    </>
  );
}

export default Barcode;

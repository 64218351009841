import styled from 'styled-components'
import { viewport } from '../../components/Viewports'

export const MainText = styled.div`

    font-family: poppins;
    font-size: 25px;
    width: 310px;
    height: 100px;
    align-items: center;
    justify-content: center;
    display:flex;
    // background-color: orange;
    font-weight: 600;


    @media (max-width: ${viewport.tablet}) {
        font-size: 21.5px;
        width: 270px;
        margin-left: 5px;
    }
`

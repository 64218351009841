import styled from "styled-components";

export const PictureContainer = styled.div`
    // background-color: orange;
    height: 210px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
;
import {
  Header,
  Cancel,
  SignIn,
  ToContinue,
  Wallpaper,
  BarcodeContainer,
} from "../../pages/Barcode/BarcodeStyles";
import BottomDescription from "../BottomDescription/BottomDescription";
import Instructions from "../Instructions/Instructions";
import CycuridLogo from "../../assets/CycurID_Title_Logo.png";
import { PictureContainer } from "../QRCode/QrCodeStyles";
import Loading from "../../assets/Loading.gif";

function Processing({ cancel }) {
  return (
    <Wallpaper>
      <BarcodeContainer>
        <Header>
          <img
            src={CycuridLogo}
            alt="logo"
            height="28.4px"
            width="100.33"
            style={{ marginLeft: 37 }}
          />
          <Cancel onClick={cancel} style={{ paddingRight: 40 }}>
            Cancel
          </Cancel>
        </Header>
        <SignIn> Loading </SignIn>
        <ToContinue>
          {/* <p> to continue to </p> 
                    <a href={merchantSite} style={{ color: '#30a43b', textDecoration: 'none' }}>&ensp;{merchantName} </a> */}
        </ToContinue>
        <PictureContainer style={{ paddingBottom: 40 }}>
          <div className="loader"></div>
        </PictureContainer>
        <Instructions />
        <BottomDescription />
      </BarcodeContainer>
    </Wallpaper>
  );
}

export default Processing;

import styled from "styled-components";
import { viewport } from "../Viewports";

export const DescriptionContainer = styled.div`
    // background-color: pink;
    height: 85px;
    width: 80%;
    border-top: 1px solid #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #7A7676;

    @media (max-width: ${viewport.tablet}) {
        font-size: 10px;
    }
`
;
import Barcode from './pages/Barcode/Barcode';
// import Complete from './pages/Complete/Complete';
// import Processing from './pages/Processing/Processing';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

export default function App() {
  return (
    <Router>
      <>
        <Switch>
          <Route path='/'>
            <Barcode/>
          </Route>
        </Switch>
      </>
    </Router>
  );
}



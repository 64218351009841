import {
  Wallpaper,
  BarcodeContainer,
  Header,
  SignIn,
} from "../../pages/Barcode/BarcodeStyles";
import { SuccessTextBox } from "../Complete/CompleteStyle";
import { PictureContainer } from "../QRCode/QrCodeStyles";
import CycuridLogo from "../../assets/CycurID_Title_Logo.png";

import Time from "../../assets/TimeOut.svg";
import { Button } from "./TimeOutStyle";

function TimeOut({ cancel }) {
  return (
    <>
      <Wallpaper>
        <BarcodeContainer>
          <Header>
            <img
              src={CycuridLogo}
              alt="logo"
              height="28.4px"
              width="100.33"
              style={{ marginLeft: 37 }}
            />
          </Header>
          <SignIn />
          <PictureContainer style={{ flexDirection: "column" }}>
            <img src={Time} alt="success" />
            <SignIn style={{ margin: 0, fontWeight: "600" }}>
              The sessions has expired.
            </SignIn>
            <SignIn style={{ margin: -13, fontWeight: "600" }}>
              Please try again
            </SignIn>
          </PictureContainer>
          <SuccessTextBox
            style={{ height: 120, justifyContent: "center", paddingBottom: 20 }}
          ></SuccessTextBox>
          <Button onClick={cancel}>Go back to sign in page</Button>
        </BarcodeContainer>
      </Wallpaper>
    </>
  );
}

export default TimeOut;

import styled from "styled-components";
import { viewport } from "../Viewports";

export const Button = styled.button`
  height: 55px;
  width: 80%;
  color: white;
  background-color: #30a43b;
  border-radius: 5px;
  font-size: 16px;
  border: 0px;
  font-style: poppins;

  @media (max-width: ${viewport.tablet}) {
    margin-bottom: 40px;
  }
`;

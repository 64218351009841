import {
  Header,
  Cancel,
  SignIn,
  ToContinue,
  Wallpaper,
  BarcodeContainer,
} from "../../pages/Barcode/BarcodeStyles";
import BottomDescription from "../BottomDescription/BottomDescription";
import CycuridLogo from "../../assets/CycurID_Title_Logo.png";
import { PictureContainer } from "../QRCode/QrCodeStyles";
import Loading from "../../assets/Loading.gif";
import TimerIcon from "../../assets/TimerIcon.svg";
import { VerifiedTimer } from "./VerifyingStyles";

function Verifying({
  merchantSite,
  merchantName,
  minutesLeft,
  secondsLeft,
  status,
}) {
  const cancel = (e) => {
    e.preventDefault();
    window.close();
  };

  return (
    <Wallpaper>
      <BarcodeContainer>
        <Header>
          <img
            src={CycuridLogo}
            alt="logo"
            height="28.4px"
            width="100.33"
            style={{ marginLeft: 37 }}
          />
          <Cancel onClick={cancel} style={{ paddingRight: 40 }}>
            Cancel
          </Cancel>
        </Header>
        {/* { status === '1'? 
                    <SignIn> Verifying </SignIn>
                : */}
        <SignIn> Waiting for authorization </SignIn>
        {/* } */}

        <ToContinue>
          <p> to continue to </p>
          <a
            href={merchantSite}
            style={{ color: "#30a43b", textDecoration: "none" }}
          >
            &ensp;{merchantName}
          </a>
        </ToContinue>
        <PictureContainer style={{ marginTop: 40 }}>
          <div className="loader"></div>
        </PictureContainer>
        <VerifiedTimer>
          <div style={{ display: "flex", alignItems: "center", width: 61 }}>
            <img src={TimerIcon} alt="clock" height="14px" />
            <p style={{ color: "#30a43b", fontWeight: "600" }}>
              &ensp;{minutesLeft}:{secondsLeft}
            </p>
          </div>
          <div>
            <span style={{ color: "#7D7D7D", fontWeight: "500" }}>
              {" "}
              Time remaining to complete your task{" "}
            </span>
          </div>
        </VerifiedTimer>
        <BottomDescription />
      </BarcodeContainer>
    </Wallpaper>
  );
}

export default Verifying;

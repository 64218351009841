import {
  Container,
  SubSection,
  TextSection,
  TitleText,
} from "../Instructions/InstructionsStyles";
import Phone from "../../assets/Phone.svg";
import Scan from "../../assets/Scan.svg";

function Instructions() {
  return (
    <Container>
      <SubSection style={{ marginTop: 10 }}>
        <img src={Phone} alt="Phone" />
        <TextSection>
          <TitleText>
            Open your <span style={{ fontWeight: 600 }}> CycurID </span> app
          </TitleText>
          <p style={{ fontSize: 9, margin: 0 }}>
            If you don't have an cycurid account yet,{" "}
            <a
              href="https://cycurid.com"
              style={{ color: "#1882E4", textDecoration: "none" }}
            >
              {" "}
              click here
            </a>
          </p>
        </TextSection>
      </SubSection>
      <SubSection>
        <img src={Scan} alt="Scan" />
        <TextSection>
          <TitleText>Scan the QR code</TitleText>
          <p style={{ fontSize: 9, margin: 0 }}>
            Login&ensp;&#62;&ensp;Scan QR Code
          </p>
        </TextSection>
      </SubSection>
    </Container>
  );
}

export default Instructions;

import styled from 'styled-components'
import { viewport } from '../../components/Viewports'


export const VerifiedTimer = styled.div`
    // background-color: pink;
    height: 40px;
    width: 80%;
    border-top: 1px solid #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    flex-direction: row;
    margin-top: 90px;
    

    @media (max-width: ${viewport.tablet}) {
        height: 40px;
        font-size: 10px;
        margin-top: 60px;
    }
`
;
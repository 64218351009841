import styled from 'styled-components'
import Background from '../../assets/background.png'
import { viewport } from '../../components/Viewports'

export const Header = styled.div`
    height: 95px;
    width: 100%;
    // background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0px;
`
;

export const Timer = styled.div`
    // background-color: pink;
    height: 40px;
    width: 80%;
    border-top: 1px solid #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    flex-direction: row;

    @media (max-width: ${viewport.tablet}) {
        height: 40px;
        font-size: 10px;
    }
`
;

export const Wallpaper = styled.div`
    background-image: url(${Background});
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
`
;

export const BarcodeContainer = styled.div`
    height: 660px;
    width: 465px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    
    @media (max-width: ${viewport.tablet}) {
        width: 350px;
        height: 600px;
    }

`
;

export const Cancel = styled.button`
    height: 30px;
    width: 30px;
    border: none;
    background: none;
    cursor: pointer; 
    font-size: 13px;
    font-family: poppins;
    margin-right: 47px;
    font-family: poppins;
`
;

export const SignIn = styled.div`
    font-family: poppins;
    font-size: 25px;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    display:flex;
    // background-color: orange;
    margin-top: 60px;

    @media (max-width: ${viewport.tablet}) {
        font-size: 20px;
        margin-top: 70px;
    }
`
;

export const ToContinue = styled.div`
    font-family: poppins;
    width: 100%;
    height: 25px;
    align-items: center;
    justify-content: center;
    display:flex;
    // background-color: yellow;
    font-size: 15px;    
    margin-bottom: 27px;

    @media (max-width: ${viewport.tablet}) {
        font-size: 13px;
        height: 25px;
    }
`
;

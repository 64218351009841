import {
  Wallpaper,
  BarcodeContainer,
  Header,
  SignIn,
} from "../../pages/Barcode/BarcodeStyles";
import { SuccessTextBox } from "./CompleteStyle";
import { PictureContainer } from "../QRCode/QrCodeStyles";
import CycuridLogo from "../../assets/CycurID_Title_Logo.png";
import Success from "../../assets/Success.svg";
import Unsuccessful from "../../assets/Error.svg";
import { useEffect } from "react";

function Complete({ merchantName, status, code, redirectURL }) {
  function sendData(code, redirectURL) {
    if (code) {
      const info = {
        code,
      };
      // window.opener.location.href = `http://localhost:3002/login?data=${code}`;
      window.opener.postMessage(info, redirectURL);
      // window.opener.postMessage(info, "http://localhost:3001/login");
      // window.opener.location.href = `${redirectURL}?data=${code}`;
      setTimeout(() => {
        window.close();
      }, 2000);
    } else {
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  }

  useEffect(() => {
    sendData(code, redirectURL);
  }, [code]);

  return (
    <>
      <Wallpaper>
        <BarcodeContainer>
          <Header>
            <img
              src={CycuridLogo}
              alt="logo"
              height="28.4px"
              width="100.33"
              style={{ marginLeft: 37 }}
            />
          </Header>
          <SignIn />
          <PictureContainer style={{ flexDirection: "column" }}>
            {status === 2 ? (
              <>
                <img src={Success} alt="success" />
                <SignIn style={{ margin: 5 }}>Access Granted</SignIn>
              </>
            ) : (
              <>
                <img src={Unsuccessful} alt="error" />
                <SignIn style={{ margin: 5 }}>Access Denied</SignIn>
              </>
            )}
          </PictureContainer>
          <SuccessTextBox style={{ paddingBottom: 20 }}>
            <p style={{ color: "#7A7676", margin: 0 }}>
              You are being redirected to
              <span style={{ color: "#30a43b" }}> {merchantName}</span>
            </p>
          </SuccessTextBox>
        </BarcodeContainer>
      </Wallpaper>
    </>
  );
}

export default Complete;
